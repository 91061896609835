import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 502.000000 476.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,476.000000) scale(0.100000,-0.100000)"
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd">
          <path d="M0 2380 l0 -2380 2510 0 2510 0 0 2380 0 2380 -2510 0 -2510 0 0
-2380z m2534 1971 c13 -12 28 -41 35 -64 7 -23 27 -73 46 -112 18 -38 35 -90
37 -115 l3 -45 -50 -3 c-91 -6 -118 20 -174 169 -12 30 -37 77 -56 104 -19 27
-35 54 -35 61 0 25 35 35 103 31 52 -3 73 -9 91 -26z m-263 9 c22 -12 69 -82
69 -103 0 -8 13 -32 28 -53 31 -44 57 -96 67 -136 5 -20 2 -29 -13 -37 -27
-15 -84 -14 -119 1 -30 13 -72 56 -73 75 0 6 -3 13 -7 15 -10 4 -43 66 -43 79
0 6 -5 15 -10 22 -6 7 -26 30 -45 52 -60 68 -42 94 63 95 35 0 72 -5 83 -10z
m511 -37 c11 -12 22 -41 27 -74 4 -30 12 -63 18 -74 35 -67 55 -187 34 -208
-11 -11 -70 2 -121 27 -31 15 -56 66 -75 154 -7 37 -25 89 -39 115 -28 51 -33
93 -14 99 26 9 150 -20 170 -39z m-735 -15 c17 -18 38 -46 46 -63 9 -16 35
-54 59 -82 24 -29 50 -69 57 -90 12 -33 12 -38 -5 -50 -10 -7 -44 -13 -80 -13
-61 0 -63 1 -98 41 -19 23 -47 60 -62 83 -15 22 -49 62 -76 88 -26 25 -48 55
-48 66 0 25 64 50 131 51 38 1 50 -4 76 -31z m894 -4 c30 -9 64 -25 76 -36 24
-22 43 -87 43 -146 0 -21 7 -59 16 -84 8 -25 14 -66 12 -90 -3 -39 -6 -43 -31
-46 -15 -2 -48 4 -73 14 -39 15 -49 25 -65 62 -11 24 -19 60 -19 80 0 40 -30
165 -47 197 -13 24 0 65 20 65 7 0 38 -7 68 -16z m-1134 -62 c162 -161 211
-223 195 -249 -7 -10 -40 -19 -113 -30 -35 -5 -41 -1 -132 89 -53 51 -113 108
-134 126 l-38 34 30 24 c17 13 39 24 50 24 11 0 27 5 35 10 31 20 68 11 107
-28z m1377 -23 c86 -32 92 -44 99 -211 8 -213 2 -227 -81 -188 -72 33 -82 51
-82 150 0 46 -7 119 -16 161 -25 115 -17 125 80 88z m-1599 -65 c19 -15 35
-30 35 -34 0 -5 23 -25 52 -46 72 -53 118 -101 118 -123 0 -21 -51 -49 -104
-58 -42 -7 -76 11 -146 75 -30 28 -77 63 -105 78 -88 45 -92 77 -16 112 84 38
112 37 166 -4z m1822 -41 c82 -41 96 -63 88 -143 -3 -36 -9 -105 -13 -155 -7
-87 -22 -135 -43 -135 -15 0 -82 45 -104 70 -15 16 -19 34 -18 68 1 26 3 103
5 172 3 112 9 150 24 150 3 0 30 -12 61 -27z m-2069 -60 c21 -9 48 -26 62 -39
14 -12 41 -30 60 -41 79 -42 140 -92 140 -113 0 -12 -113 -70 -136 -70 -21 0
-110 47 -149 78 -16 14 -62 40 -102 59 -40 19 -73 39 -73 44 0 26 109 98 148
99 8 0 30 -7 50 -17z m2254 -56 c7 -7 30 -23 52 -36 52 -32 69 -74 56 -141 -5
-28 -14 -59 -20 -70 -5 -10 -10 -31 -10 -47 0 -29 -29 -128 -43 -150 -14 -22
-33 -14 -82 32 l-48 45 6 69 c4 37 14 85 22 104 9 21 15 67 15 110 0 87 18
117 52 84z m-897 -67 c39 -8 72 -17 75 -21 3 -3 29 -10 58 -14 28 -4 52 -10
52 -15 0 -4 15 -10 33 -13 35 -5 103 -29 113 -40 4 -4 -9 -7 -29 -7 -20 0 -37
4 -37 9 0 5 -18 11 -40 15 -22 3 -40 10 -40 15 0 5 -25 13 -55 16 -30 4 -55
11 -55 16 0 5 -20 9 -45 9 -25 0 -62 7 -82 15 -28 12 -86 15 -285 15 -169 0
-247 3 -243 10 9 15 491 6 580 -10z m-1555 -20 c43 -24 121 -62 178 -85 23
-10 42 -21 42 -25 0 -4 9 -10 20 -13 35 -9 24 -49 -23 -82 -58 -41 -101 -39
-203 11 -43 21 -108 48 -144 60 -35 11 -66 27 -68 33 -5 15 66 91 85 91 7 0
13 4 13 9 0 9 21 18 50 20 8 0 31 -8 50 -19z m944 11 c7 -11 -28 -21 -76 -21
-21 0 -38 -4 -38 -9 0 -14 -80 -25 -92 -13 -7 7 1 13 28 17 22 4 48 10 59 15
39 18 111 24 119 11z m-219 -61 c-3 -5 -22 -12 -43 -16 -20 -4 -59 -17 -87
-29 -27 -13 -57 -26 -65 -29 -8 -3 -24 -11 -35 -18 -30 -17 -46 -25 -90 -48
-22 -11 -53 -29 -70 -40 -16 -11 -37 -23 -45 -27 -8 -3 -17 -9 -20 -12 -8 -9
-100 -71 -105 -71 -3 0 -5 5 -5 10 0 6 4 10 9 10 6 0 25 14 43 30 18 17 38 30
44 30 7 0 14 3 16 8 4 10 96 72 107 72 5 0 14 7 21 15 7 8 21 15 31 15 11 0
19 4 19 10 0 5 12 13 28 16 15 4 47 18 71 31 24 12 54 23 66 23 13 0 25 7 29
15 3 10 19 15 46 15 24 0 39 -4 35 -10z m1950 -38 c88 -75 94 -124 34 -244 -5
-10 -9 -27 -9 -39 0 -11 -6 -33 -14 -47 -8 -15 -22 -46 -31 -69 -11 -27 -25
-43 -35 -43 -9 0 -36 24 -59 52 -40 50 -42 55 -36 98 4 25 12 59 19 75 29 68
39 103 46 157 4 31 11 61 14 67 10 16 48 13 71 -7z m-725 -22 c3 -4 16 -11 29
-15 14 -4 39 -16 55 -26 17 -11 39 -22 49 -25 23 -8 22 -24 -3 -24 -11 0 -20
3 -20 8 0 4 -25 18 -55 31 -30 14 -55 28 -55 33 0 4 -11 8 -24 8 -26 0 -40 12
-29 24 7 7 45 -3 53 -14z m-2123 -54 c57 -29 104 -46 123 -46 11 0 28 -4 38
-9 9 -5 34 -17 56 -27 21 -11 41 -25 44 -33 3 -8 -14 -32 -42 -59 -48 -45 -48
-45 -105 -39 -32 4 -71 13 -87 22 -16 8 -34 15 -40 15 -7 0 -25 7 -40 15 -16
8 -47 15 -69 15 -49 0 -87 23 -78 48 3 9 28 38 56 65 56 51 90 59 144 33z
m2323 -56 c0 -5 6 -10 13 -10 19 -1 70 -47 59 -54 -5 -3 -15 1 -22 9 -7 8 -19
15 -27 15 -7 0 -16 7 -19 15 -4 8 -12 15 -19 15 -8 0 -17 5 -20 10 -3 6 3 10
14 10 12 0 21 -4 21 -10z m697 -50 c45 -41 73 -87 73 -119 0 -30 -36 -112 -52
-119 -5 -2 -8 -9 -8 -15 0 -6 -19 -48 -42 -94 -37 -75 -44 -84 -67 -81 -16 2
-35 18 -55 46 -34 48 -38 94 -13 146 9 17 18 38 21 46 4 8 15 29 25 45 11 17
20 37 20 45 1 8 8 28 16 44 8 15 15 36 15 45 0 36 34 42 67 11z m-557 -50 c18
-16 36 -30 41 -30 5 0 9 -4 9 -10 0 -18 -21 -10 -53 20 -18 16 -35 30 -39 30
-5 0 -8 5 -8 10 0 18 17 11 50 -20z m-2590 -40 c10 -6 34 -10 53 -10 48 0 164
-39 171 -57 4 -9 0 -23 -7 -32 -74 -86 -65 -81 -136 -81 -36 1 -84 7 -106 15
-22 8 -72 15 -111 15 -88 0 -115 15 -95 53 7 14 33 46 57 72 l43 48 57 -7 c31
-3 64 -11 74 -16z m450 7 c0 -11 -160 -155 -164 -148 -7 11 112 131 130 131 8
0 12 5 9 10 -3 6 1 10 9 10 9 0 16 -2 16 -3z m2273 -83 c33 -33 54 -61 50 -68
-5 -7 -9 -5 -11 7 -2 9 -8 17 -14 17 -13 0 -78 65 -78 78 0 6 -6 13 -12 15 -8
3 -8 6 -2 6 6 1 36 -24 67 -55z m589 -49 c14 -16 30 -40 37 -52 15 -29 14
-100 -1 -110 -15 -9 -36 -41 -44 -65 -4 -10 -10 -18 -13 -18 -4 0 -18 -21 -30
-46 -29 -57 -94 -126 -112 -119 -22 8 -72 122 -65 149 3 12 18 40 32 62 49 71
104 164 104 175 0 6 9 24 21 40 23 33 33 30 71 -16z m-3032 -30 c0 -3 -9 -14
-21 -26 -26 -26 -39 -16 -14 11 17 19 35 26 35 15z m2554 -39 c50 -53 109
-136 102 -144 -3 -3 -7 -3 -9 -1 -2 2 -19 27 -38 54 -19 28 -51 65 -71 84 -35
31 -47 51 -32 51 4 0 25 -20 48 -44z m-2889 -5 c104 -11 145 -25 145 -48 0
-16 -44 -78 -74 -105 -24 -23 -100 -24 -179 -3 -40 10 -64 12 -82 5 -38 -15
-101 -12 -114 4 -15 18 13 77 61 132 36 41 14 39 243 15z m249 -58 c-16 -21
-44 -60 -61 -87 -26 -39 -33 -45 -33 -28 0 12 5 22 10 22 6 0 10 6 10 13 0 8
20 36 45 63 25 27 45 53 45 58 0 5 3 6 6 3 4 -4 -6 -23 -22 -44z m1505 13 c8
-9 11 -73 9 -207 l-3 -194 -30 0 c-27 0 -30 4 -37 43 -10 57 -52 150 -81 175
-54 47 -101 58 -251 55 l-141 -3 0 -175 0 -175 380 -6 c380 -6 459 -13 555
-47 143 -51 238 -155 274 -299 36 -143 -11 -292 -124 -397 -22 -20 -42 -36
-45 -36 -3 0 -22 -10 -43 -23 -20 -13 -55 -28 -77 -35 -22 -6 -49 -18 -59 -25
-18 -13 -18 -15 5 -49 57 -85 115 -166 149 -208 20 -25 47 -62 61 -82 13 -21
27 -38 32 -38 4 0 7 -5 7 -11 0 -7 15 -30 33 -53 18 -23 40 -53 49 -67 27 -43
130 -139 173 -161 45 -24 89 -37 155 -46 42 -7 45 -9 48 -41 2 -20 -2 -37 -10
-42 -7 -5 -111 -9 -231 -9 -249 0 -224 -9 -302 106 -22 33 -42 61 -46 64 -3 3
-26 34 -50 69 -47 68 -65 93 -174 245 -38 54 -77 108 -85 120 -8 12 -37 52
-63 90 l-49 67 -71 -3 -72 -3 0 -280 c0 -309 4 -333 59 -363 17 -9 60 -19 95
-22 73 -6 89 -19 72 -63 l-11 -27 -349 0 c-384 0 -385 0 -365 58 9 25 14 27
76 30 58 3 70 7 104 36 l39 34 0 173 0 174 -323 3 -323 2 3 38 3 37 65 6 c84
7 124 30 145 84 14 38 15 117 14 689 -2 355 -6 651 -9 657 -5 7 -56 9 -157 7
-147 -3 -151 -4 -199 -33 -58 -37 -104 -115 -104 -181 0 -50 -17 -66 -62 -62
l-33 3 -3 185 c-1 102 0 195 3 208 l5 22 679 0 c564 0 680 -2 690 -14z m1723
-73 c8 -10 25 -40 38 -66 31 -67 23 -108 -33 -166 -24 -25 -56 -64 -72 -86
-47 -65 -82 -95 -108 -95 -21 0 -29 8 -43 47 -33 85 -23 116 74 223 12 14 37
48 54 75 37 58 58 85 68 85 4 0 14 -8 22 -17z m-453 -59 c3 -16 1 -17 -13 -5
-19 16 -21 34 -3 29 6 -3 14 -13 16 -24z m-3013 -38 c19 -14 18 -16 -9 -71
-36 -74 -67 -88 -175 -79 -58 5 -88 2 -122 -10 -63 -22 -120 -21 -120 2 0 34
35 114 62 143 l28 29 158 0 c121 0 163 -3 178 -14z m134 5 c0 -6 -4 -13 -10
-16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m2897 -13 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-2935 -50 c-7 -7 -12 -8 -12 -2 0
14 12 26 19 19 2 -3 -1 -11 -7 -17z m-31 -45 c-12 -20 -14 -14 -5 12 4 9 9 14
11 11 3 -2 0 -13 -6 -23z m3508 -31 c5 -10 19 -46 31 -80 l22 -62 -22 -35
c-12 -19 -41 -48 -64 -64 -23 -16 -55 -44 -71 -63 -44 -51 -112 -101 -128 -95
-7 3 -22 32 -32 66 -23 77 -13 101 80 180 22 19 62 62 89 95 66 82 78 90 95
58z m-3525 -17 c-6 -16 -24 -21 -24 -7 0 11 11 22 21 22 5 0 6 -7 3 -15z
m3076 -13 c0 -15 -2 -15 -10 -2 -13 20 -13 33 0 25 6 -3 10 -14 10 -23z
m-3220 -32 c20 -20 20 -20 -1 -72 -11 -29 -23 -56 -25 -60 -12 -18 -83 -42
-149 -49 -90 -9 -154 -24 -168 -38 -17 -17 -45 -13 -57 8 -7 14 -5 34 6 73 9
29 18 59 20 65 7 26 68 53 119 53 28 1 88 9 135 19 47 11 88 19 93 20 4 1 16
-8 27 -19z m107 -22 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m3642 -175 c7 -14 15 -55 18 -89 6 -62 5 -64 -27 -94 -19 -17 -50 -40 -69 -50
-20 -10 -50 -32 -68 -48 -17 -16 -37 -32 -45 -36 -7 -3 -28 -15 -47 -27 -46
-27 -60 -12 -67 72 -6 76 1 86 112 162 39 26 87 68 108 92 42 51 67 57 85 18z
m-3810 -12 c12 -8 12 -17 1 -58 -26 -99 -63 -125 -219 -150 -19 -3 -56 -19
-83 -34 -87 -51 -105 -36 -82 72 17 82 35 105 94 114 19 3 62 15 95 26 111 39
166 47 194 30z m-45 -266 c0 -27 -3 -61 -7 -75 -7 -25 -66 -70 -93 -70 -8 0
-22 -4 -32 -9 -9 -6 -47 -24 -84 -42 -37 -17 -73 -38 -79 -45 -6 -8 -22 -14
-35 -14 -30 0 -39 31 -31 110 6 67 35 104 90 115 18 4 61 21 97 38 77 37 105
46 145 44 29 -2 30 -3 29 -52z m3894 43 c7 -7 12 -42 12 -88 0 -65 -3 -79 -24
-101 -13 -14 -39 -31 -57 -38 -19 -7 -41 -17 -49 -22 -8 -5 -40 -24 -70 -42
-69 -42 -113 -58 -131 -46 -10 6 -14 27 -13 69 2 80 20 104 115 153 41 21 99
58 129 82 60 47 70 51 88 33z m-411 -395 c-1 -59 -3 -13 -3 102 0 116 2 164 3
108 2 -56 2 -151 0 -210z m-3499 119 c3 -70 2 -74 -26 -100 -16 -15 -47 -36
-68 -47 -54 -27 -136 -79 -154 -97 -28 -27 -54 -37 -72 -27 -15 8 -18 23 -18
92 l1 82 43 33 c24 17 49 32 55 32 15 0 134 71 139 83 2 4 14 7 27 7 13 0 27
5 30 11 4 5 14 9 23 7 14 -3 18 -18 20 -76z m3930 -62 c-6 -94 -22 -114 -116
-140 -37 -10 -68 -23 -70 -29 -2 -6 -11 -11 -21 -11 -9 0 -26 -7 -38 -15 -12
-8 -43 -16 -70 -17 l-48 -3 3 60 c3 91 9 98 125 149 21 9 45 16 54 16 9 0 30
11 47 24 17 13 49 33 70 44 58 31 69 18 64 -78z m-3841 -102 c-2 -18 -4 -6 -4
27 0 33 2 48 4 33 2 -15 2 -42 0 -60z m-88 13 c10 -6 16 -32 19 -73 5 -76 -7
-94 -101 -161 -34 -25 -85 -68 -114 -97 -65 -64 -84 -63 -99 5 -28 119 -20
139 76 200 25 15 56 39 70 53 14 13 34 28 45 33 11 5 34 18 50 28 36 23 36 23
54 12z m3910 -80 c12 -8 11 -21 -2 -87 -18 -91 -35 -109 -112 -120 -27 -3 -52
-9 -55 -13 -5 -6 -54 -21 -135 -42 -50 -13 -85 -5 -85 20 0 11 5 42 12 69 9
43 17 53 47 69 20 10 38 21 39 26 2 4 16 7 30 7 42 0 123 25 172 53 49 27 68
31 89 18z m-3785 -150 c-10 -24 -25 47 -17 84 6 28 7 27 15 -19 5 -27 6 -56 2
-65z m3351 22 c-9 -52 -23 -22 -17 37 4 39 6 42 15 23 5 -12 6 -39 2 -60z
m-3438 -15 c13 -17 33 -90 33 -117 0 -15 -32 -55 -90 -114 -50 -51 -102 -109
-115 -130 -26 -37 -55 -47 -72 -24 -14 18 -43 112 -43 140 0 25 9 35 151 170
92 87 116 100 136 75z m3404 -108 c-9 -22 -10 -20 -10 21 -1 25 2 49 7 53 11
12 14 -47 3 -74z m-3292 28 c0 -38 -1 -40 -10 -19 -8 22 -4 61 7 61 2 0 4 -19
3 -42z m3719 -42 c4 -20 -25 -122 -42 -144 -16 -23 -76 -42 -130 -42 -29 0
-76 -7 -105 -16 -29 -8 -71 -14 -94 -12 -39 3 -42 5 -44 33 -2 17 7 50 20 74
18 36 31 47 72 62 28 11 74 19 103 19 29 0 60 4 70 9 30 17 106 39 127 37 11
0 21 -10 23 -20z m-445 -39 c-3 -12 -11 -36 -19 -54 -8 -17 -14 -38 -14 -47 0
-9 -4 -16 -10 -16 -5 0 -10 -11 -10 -24 0 -14 -7 -31 -15 -40 -8 -8 -15 -21
-15 -28 0 -8 -4 -18 -9 -23 -5 -6 -25 -39 -43 -75 -47 -90 -60 -110 -85 -139
-13 -14 -23 -29 -23 -33 0 -3 -13 -20 -30 -36 -16 -17 -30 -33 -30 -36 0 -10
-106 -123 -173 -183 -40 -37 -47 -40 -47 -18 0 8 4 15 8 15 9 0 111 99 172
167 19 21 58 72 85 113 53 79 62 92 83 115 6 7 12 20 12 28 0 8 7 17 15 21 8
3 15 11 15 18 0 7 14 40 30 73 16 33 30 66 30 73 0 6 5 12 10 12 6 0 10 13 10
29 0 16 7 34 15 41 8 7 15 25 15 40 0 14 3 30 6 33 12 12 22 -3 17 -26z
m-3243 -4 c0 -14 7 -34 16 -44 9 -10 14 -29 12 -46 -4 -25 -5 -26 -12 -8 -4
11 -14 33 -22 48 -16 33 -19 77 -4 77 6 0 10 -12 10 -27z m-70 -65 c23 -38 36
-110 24 -131 -9 -15 -67 -86 -95 -117 -9 -9 -33 -44 -54 -78 -49 -80 -60 -89
-83 -69 -27 24 -66 132 -58 161 10 40 209 266 235 266 6 0 20 -14 31 -32z
m160 -148 c15 -30 25 -61 22 -69 -3 -8 0 -11 7 -7 6 4 15 -2 21 -16 10 -24 31
-59 40 -68 14 -14 70 -99 70 -107 0 -5 8 -16 18 -24 24 -22 92 -97 92 -103 0
-4 45 -49 137 -135 21 -20 55 -47 76 -61 38 -26 47 -40 25 -40 -31 0 -307 267
-357 345 -14 22 -36 51 -48 65 -13 14 -23 30 -23 37 0 7 -4 13 -9 13 -5 0 -14
12 -20 28 -14 34 -59 114 -71 126 -6 6 -10 18 -10 28 0 10 -4 18 -9 18 -11 0
-19 40 -13 60 4 10 8 7 15 -10 5 -14 22 -50 37 -80z m3520 4 c0 -40 -39 -121
-68 -140 -21 -14 -56 -18 -167 -21 -157 -4 -205 1 -205 22 0 17 47 104 68 125
11 11 50 16 149 21 73 3 136 9 140 12 3 4 24 7 45 7 34 0 38 -3 38 -26z
m-3581 -81 c37 -69 38 -82 6 -133 -14 -22 -25 -44 -25 -48 0 -5 -12 -22 -26
-37 -25 -27 -64 -104 -64 -128 -1 -7 -9 -22 -20 -35 -24 -29 -45 -18 -89 49
-49 76 -42 121 37 209 12 13 22 31 22 38 0 26 95 142 116 142 7 0 26 -26 43
-57z m3326 -126 c17 -1 51 1 76 5 32 5 52 3 64 -6 17 -12 17 -17 5 -47 -17
-45 -55 -95 -86 -115 -22 -15 -32 -15 -113 0 -49 9 -118 16 -153 16 -51 0 -69
4 -82 19 -16 17 -16 21 1 53 10 19 34 47 53 62 34 27 38 27 120 22 47 -4 99
-8 115 -9z m-3211 -64 c33 -52 36 -63 30 -103 -4 -25 -10 -47 -14 -50 -14 -9
-68 -120 -75 -152 -4 -18 -11 -37 -15 -43 -4 -5 -10 -22 -14 -37 -9 -37 -20
-35 -65 10 -52 52 -71 82 -71 114 0 30 37 108 51 108 5 0 9 8 9 18 0 21 78
173 94 184 23 15 35 6 70 -49z m3032 -128 c12 -9 44 -14 92 -15 72 0 112 -11
112 -30 0 -23 -67 -110 -96 -126 -27 -15 -39 -16 -85 -6 -30 7 -83 17 -119 23
-36 6 -69 15 -75 20 -5 5 -21 9 -36 9 -17 0 -34 8 -43 22 -14 20 -13 24 17 62
17 23 36 44 42 48 19 14 170 9 191 -7z m-2895 -47 c39 -50 41 -55 35 -102 -3
-27 -12 -58 -19 -70 -8 -11 -17 -34 -20 -51 -11 -53 -35 -156 -41 -171 -10
-27 -45 -14 -95 35 -66 64 -67 96 -11 211 4 9 11 34 14 55 4 22 16 56 26 75
11 19 22 43 26 53 11 31 43 18 85 -35z m2659 -103 c19 -4 40 -11 45 -15 17
-12 107 -38 158 -45 87 -13 92 -41 19 -117 -47 -49 -83 -55 -152 -28 -30 13
-87 33 -127 46 -85 27 -123 50 -123 73 0 32 99 105 130 97 8 -3 31 -8 50 -11z
m-2505 -46 c42 -39 45 -45 45 -94 0 -29 -9 -81 -21 -116 -15 -47 -19 -78 -15
-117 7 -58 -5 -92 -32 -92 -9 0 -43 21 -76 46 l-59 46 5 70 c3 38 11 75 17 81
6 6 11 32 11 58 0 26 7 60 15 75 8 16 15 39 15 51 0 21 11 31 38 32 7 1 33
-18 57 -40z m2275 31 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
6 0 10 -4 10 -10z m-46 -46 c-30 -24 -44 -29 -44 -17 0 14 53 53 62 47 6 -4
-3 -17 -18 -30z m-2029 -34 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10
10 10 2 0 7 -4 10 -10z m1969 1 c7 -10 -93 -75 -102 -67 -6 7 -9 5 47 45 50
35 48 34 55 22z m-1919 -31 c3 -5 -3 -10 -14 -10 -12 0 -21 5 -21 10 0 6 6 10
14 10 8 0 18 -4 21 -10z m2085 -23 c91 -49 151 -76 195 -86 27 -7 55 -17 62
-23 14 -11 18 -48 5 -48 -4 0 -18 -10 -32 -23 -76 -69 -101 -72 -213 -18 -45
22 -89 42 -97 45 -8 3 -24 12 -35 18 -61 39 -75 51 -75 63 0 8 21 30 46 50 51
38 99 46 144 22z m-2160 -33 c29 -21 53 -47 57 -62 3 -14 1 -67 -6 -118 -7
-52 -9 -114 -6 -139 14 -90 -19 -121 -78 -75 -14 11 -30 20 -35 20 -6 0 -22
14 -37 32 -24 28 -26 37 -21 82 3 28 10 98 14 156 5 58 12 113 17 123 11 26
40 21 95 -19z m110 26 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10
6 0 11 -4 11 -10z m60 -30 c0 -13 -20 -13 -40 0 -12 8 -9 10 13 10 15 0 27 -4
27 -10z m1692 -2 c-9 -9 -15 -9 -24 0 -9 9 -7 12 12 12 19 0 21 -3 12 -12z
m-1652 -18 c12 -8 11 -10 -7 -10 -13 0 -23 5 -23 10 0 13 11 13 30 0z m1613
-7 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-1548 -23 c3 -5 -3
-10 -14 -10 -11 0 -23 5 -26 10 -3 6 3 10 14 10 11 0 23 -4 26 -10z m1490 0
c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z
m-1597 -44 c74 -38 78 -47 85 -182 3 -65 11 -133 17 -151 6 -17 10 -44 8 -60
-3 -25 -6 -28 -36 -25 -44 3 -105 35 -131 68 -19 25 -21 39 -21 188 0 113 4
166 12 174 15 15 11 16 66 -12z m178 9 c9 -8 25 -15 37 -15 15 0 18 -3 10 -11
-12 -12 -53 -3 -53 12 0 5 -9 9 -19 9 -11 0 -23 5 -26 10 -10 16 35 12 51 -5z
m1364 5 c0 -5 -12 -10 -26 -10 -14 0 -23 4 -19 10 3 6 15 10 26 10 10 0 19 -4
19 -10z m255 -7 c19 -9 35 -20 35 -25 0 -4 6 -8 13 -8 8 0 27 -11 43 -25 30
-26 107 -65 129 -65 19 0 46 -36 39 -53 -7 -20 -89 -72 -126 -81 -34 -8 -101
20 -121 51 -4 7 -33 25 -63 40 -66 32 -134 89 -134 111 0 21 84 69 122 71 15
0 43 -7 63 -16z m-320 -23 c-3 -5 -15 -10 -26 -10 -10 0 -19 5 -19 10 0 6 12
10 26 10 14 0 23 -4 19 -10z m-1180 -20 c17 -7 14 -9 -13 -9 -20 -1 -31 3 -27
9 7 12 13 12 40 0z m1108 -7 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2
23 -4z m-1003 -23 c11 -7 7 -10 -18 -10 -20 0 -31 4 -27 10 8 13 26 13 45 0z
m925 0 c4 -6 -8 -10 -30 -10 -22 0 -34 4 -30 10 3 6 17 10 30 10 13 0 27 -4
30 -10z m-1093 -42 c47 -23 66 -65 74 -162 3 -39 9 -80 14 -91 26 -62 41 -115
35 -124 -9 -16 -62 -12 -120 7 -43 15 -53 24 -72 62 -16 30 -23 62 -23 100 0
30 -4 71 -9 90 -37 145 -12 175 101 118z m1379 -1 c55 -48 128 -105 184 -140
28 -18 50 -37 49 -42 -1 -21 -84 -66 -132 -72 -57 -6 -62 -4 -137 62 -191 168
-194 174 -115 206 69 28 109 24 151 -14z m-1091 13 c11 -7 4 -10 -27 -10 -24
0 -43 5 -43 10 0 13 50 13 70 0z m690 0 c0 -5 -16 -10 -36 -10 -21 0 -33 4
-29 10 3 6 19 10 36 10 16 0 29 -4 29 -10z m-230 -30 c0 -11 -125 -12 -270 0
-85 6 -77 7 88 8 116 1 182 -2 182 -8z m-562 -34 c30 -8 60 -23 68 -33 18 -24
39 -85 55 -158 7 -33 17 -67 23 -75 6 -8 20 -32 30 -52 34 -67 -7 -82 -133
-48 -39 10 -71 51 -71 90 0 23 -23 102 -46 158 -8 18 -14 55 -14 82 0 57 3 58
88 36z m952 -1 c17 -9 30 -19 30 -24 0 -12 127 -137 167 -163 18 -13 35 -29
38 -37 9 -22 -27 -48 -93 -66 -57 -16 -62 -16 -90 0 -16 10 -40 31 -53 47 -13
16 -54 61 -92 100 -65 69 -89 111 -71 128 12 11 73 28 107 29 15 1 41 -6 57
-14z m-195 -78 c22 -22 44 -48 50 -57 35 -57 57 -85 94 -116 66 -57 54 -86
-42 -105 -89 -18 -102 -11 -177 101 -8 12 -30 39 -47 59 -18 20 -33 42 -33 49
0 6 -4 12 -8 12 -4 0 -14 16 -21 36 -16 44 -7 55 51 64 64 10 89 2 133 -43z
m-491 28 c20 -8 36 -21 36 -29 0 -7 7 -19 15 -26 8 -7 15 -23 15 -36 0 -12 4
-25 9 -29 5 -3 13 -20 16 -38 4 -17 17 -45 28 -61 27 -39 57 -97 57 -112 0
-18 -30 -22 -108 -15 -77 7 -104 27 -118 86 -4 17 -21 62 -39 100 -37 79 -59
151 -50 165 9 15 98 12 139 -5z m225 -6 c17 -6 31 -15 31 -19 0 -5 14 -29 30
-53 17 -25 30 -50 30 -56 0 -6 3 -11 8 -11 4 0 15 -17 25 -38 10 -20 31 -52
47 -71 56 -63 31 -91 -80 -91 -52 0 -62 3 -85 28 -14 15 -28 35 -31 45 -3 9
-10 17 -15 17 -5 0 -9 11 -9 24 0 13 -7 29 -15 36 -8 7 -15 16 -15 21 0 4 -11
25 -25 45 -36 54 -53 113 -34 125 20 12 100 11 138 -2z"/>
<path d="M2007 2714 c-3 -11 -8 -203 -11 -429 -6 -475 -4 -490 64 -525 22 -11
50 -20 64 -20 21 0 26 6 32 34 3 19 4 216 2 438 -4 342 -7 409 -20 440 -18 41
-49 65 -95 74 -25 5 -32 2 -36 -12z"/>
<path d="M2453 2670 c-12 -5 -25 -19 -28 -32 -3 -13 -4 -156 -3 -318 l3 -295
50 -3 c57 -4 178 16 215 35 14 7 31 13 38 13 19 0 117 75 139 107 34 49 53
116 53 186 0 37 -4 75 -9 85 -6 9 -12 28 -15 42 -10 43 -85 127 -134 150 -15
6 -40 18 -55 26 -32 16 -217 19 -254 4z"/>
</g>
</svg>





    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
